<template>
  <div class="error">
    <client-only>
      <div class="error-page container">
        <div class="error-page__content">
          <div class="error-page__content--img">
            <BaseImg src="/assets/images/components/common/maintain/404.webp" alt="icon" class="404 error" />
            <span>Lỗi 404 - Không tìm thấy trang</span>
            <div class="infos">Xin lỗi quý khách! Chúng tôi không tìm thấy trang bạn đang tìm kiếm</div>
            <BaseButton class="base-button btn base-button--bg-bordeaux-fill" @click="goHome">
              Quay lại trang chủ
            </BaseButton>
          </div>
        </div>
      </div>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import BaseButton from '~/components/common/base-button.vue'
import BaseImg from '~/components/common/base-img.vue'

const router = useRouter()

const goHome = () => {
  router.push('/')
}
</script>

<style scoped lang="scss" src="~/assets/scss/page/error-pc.scss"></style>
